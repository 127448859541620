var $ = window.jQuery;
(function($) {

  /**
   * Cookie box constructor.
   *
   * @param  {string} selector
   */
  function CookieBox(selector) {
    this.init(selector);
  }

  CookieBox.prototype = {

    /**
     * The cookie key.
     *
     * @type {string}
     */
    key: 'transarb',

    /**
     * Initialize the module.
     *
     * @param  {string} selector
     */
    init: function(selector) {
      this.$el = $(selector);

      if (!this.$el.length) {
        return;
      }

      this.binds();
      this.showBox();
    },

    /**
     * Accept cookie information.
     *
     * @param  {object} e
     */
    accept: function(e) {
      e.preventDefault();
      $(e.currentTarget).closest('.alert-message').hide();
      document.cookie = this.key + '=ok; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    },

    /**
     * Bind elements with events.
     */
    binds: function() {
      this.$el.bind('click', '.btn.btn-blue', this.accept.bind(this));
    },

    /**
     * Show box if no cookie is set.
     */
    showBox: function() {
      if (document.cookie.indexOf(this.key + '=') !== -1) {
        return this.$el.hide();
      }

      this.$el.show();
    }

  };

  new CookieBox('.cookie-box');
})(window.jQuery);
